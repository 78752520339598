import React from "react";
import imageJpg1 from "@assets/img/Main/Features/1.jpg";
import imageWebp1 from "@assets/img/Main/Features/1.webp";
import imageJpg2 from "@assets/img/Main/Features/2.jpg";
import imageWebp2 from "@assets/img/Main/Features/2.webp";
import imageJpg3 from "@assets/img/Main/Features/3.jpg";
import imageWebp3 from "@assets/img/Main/Features/3.webp";

const Features = () => (
  <section className="features">
    <ul className="features__list">
      <li className="pl pr">
        <picture className="about__image">
          <source srcSet={imageWebp1} type="image/webp" />
          <img src={imageJpg1} alt="новый спротивный зал" loading="lazy" />
        </picture>
        <h3 className="subtitle">большой спортивный зал</h3>
        <p>
          <span>
            Площадь — 250 м<sup>2</sup>
          </span>
        </p>
        <a
          className='button'
          style={{display: 'inline-block', marginTop: '20px'}}
          // eslint-disable-next-line max-len
          href="https://yandex.ru/maps?ll=92.900575%2C56.019772&panorama%5Bpoint%5D=92.900575%2C56.019772&panorama%5Bdirection%5D=17.399773%2C0.879610&panorama%5Bid%5D=1627915341_670401319_23_1612514019&panorama%5Bspan%5D=35.453564%2C79.999985"
          target="_blank"
          rel="noreferrer"
        >
          Тур по залу
        </a>
      </li>
      <li className="pl pr">
        <picture className="about__image">
          <source srcSet={imageWebp2} type="image/webp" />
          <img
            src={imageJpg2}
            alt="профессиональное оборудование"
            loading="lazy"
          />
        </picture>
        <h3 className="subtitle">лучшее оборудование для занятий кроссфитом</h3>
        <p>
          <span>Самый оборудованный кроссфит-зал в Красноярске</span>
          {/* <span>Все необходимые принадлежности</span> */}
        </p>
      </li>
      <li className="pl pr">
        <picture className="about__image">
          <source srcSet={imageWebp3} type="image/webp" />
          <img src={imageJpg3} alt="квалифицированные тренеры" loading="lazy" />
        </picture>
        <h3 className="subtitle">опытные тренеры</h3>
        <p>
          <span>CrossFit, питание, восстановление</span>
        </p>
      </li>
    </ul>
  </section>
);

export default Features;
